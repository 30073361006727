import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Widget from "./components/Widget/Widget";

function App() {
    return (
        <Container>
            <Row>
                <Col xs="auto">
                    <Widget title="Click War" link="/click-war"></Widget>
                </Col>
                <Col xs="auto">
                    <Widget title="Secret Santa" link="/secret-santa"></Widget>
                </Col>
                <Col xs="auto">
                    <Widget
                        title="Transform Text"
                        link="/transform-text"
                    ></Widget>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
