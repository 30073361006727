import React, { useEffect, useMemo, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import io from "socket.io-client";

import { SOCKET_ADRESS } from "../../constants";

type QRCodeProps = {
    onAddName: (name: string) => void;
    editMode: boolean;
};

const QRCode: React.FC<QRCodeProps> = (props) => {
    const socket = useMemo(
        () =>
            io(SOCKET_ADRESS, {
                reconnectionAttempts: 5,
            }),
        []
    );
    const [socketId, setSocketId] = useState("");

    useEffect(() => {
        socket.on("createRoom", (msg) => {
            setSocketId(msg);
        });

        socket.on("roomJoined", () => {
            socket.emit("setMode", props.editMode);
        });

        socket.on("addName", (name) => {
            props.onAddName(name);
        });

        socket.emit("createRoom");

        return () => {
            socket.off("createRoom");
            socket.off("roomJoined");
            socket.off("addName");
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        socket.emit("setMode", props.editMode);
    }, [props.editMode]);

    return (
        <>
            {socketId && props.editMode && (
                <>
                    <QRCodeSVG
                        size={256}
                        value={`${window.location.href}/join/${socketId}`}
                    />
                    <div className="text-center">
                        Lien :{" "}
                        <a
                            href={`${window.location.href}/join/${socketId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {socketId}
                        </a>
                    </div>
                </>
            )}
        </>
    );
};

export default QRCode;
