import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import io from "socket.io-client";

import { SOCKET_ADRESS } from "../../constants";

type JoinSecretSantaProps = {};

const JoinSecretSanta: React.FC<JoinSecretSantaProps> = (props) => {
    const socket = useMemo(() => io(SOCKET_ADRESS), []);

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [isEditable, setIsEditable] = useState(false);

    const { id } = useParams();

    const submitForm = (e: any) => {
        e.preventDefault();
        socket.emit("addName", id, e.target.elements.name.value);
        e.target.elements.name.value = "";
    };

    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });

        socket.on("setMode", (edit: boolean) => {
            setIsEditable(edit);
        });

        socket.emit("joinRoom", id);

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("setMode");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {id && isConnected ? (
                isEditable ? (
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col>
                                <Form.Group controlId="name">
                                    <Form.Label>
                                        Ajouter une personne
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="d-flex align-items-center">
                                <Button variant="primary" type="submit">
                                    Ajouter
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Row>
                        <Col>
                            Impossible d'ajouter des membres en mode affichage.
                        </Col>
                    </Row>
                )
            ) : (
                <Row>
                    <Col>
                        Un problème de communication est survenu, veuillez
                        réessayer.
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default JoinSecretSanta;
