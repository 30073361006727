import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Socket } from "socket.io-client";

type GameProps = {
    socket: Socket;
};

const Game: React.FC<GameProps> = ({ socket }) => {
    const [score, setScore] = useState(0);
    const [time, setTime] = useState(10);
    const [win, setWin] = useState(false);

    const addOne = () => socket.emit("addOne");

    useEffect(() => {
        socket.on("updateScore", ({ score, time }) => {
            setScore(score);
            setTime(Math.ceil(time));
        });

        socket.on("gameEnded", (win) => {
            setTime(-1);
            setWin(win);
        });

        return () => {
            socket.off("updateScore");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <Col>Game</Col>
            </Row>
            <Row>
                <Col>{time < 0 ? 0 : time} secondes</Col>
            </Row>
            <Row>
                <Form.Range value={score} min={-100} max={100} readOnly />
            </Row>
            <Row>
                {time < 0 ? (
                    <>
                        Bien joué ! Score: {score} (Tu as{" "}
                        {win ? "gagné" : "perdu"})
                    </>
                ) : (
                    <Button
                        onClick={addOne}
                        style={{
                            width: 300,
                            height: 300,
                            borderRadius: "50%",
                            margin: "auto",
                        }}
                    >
                        CLICK !!!
                    </Button>
                )}
            </Row>
        </>
    );
};

export default Game;
