import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouteObject,
    RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ClickWar, SecretSanta, TransformText } from "./lib";

let routes: RouteObject[] = [
    {
        path: "/",
        element: <App />,
    },
];

routes = routes.concat(ClickWar);
routes = routes.concat(SecretSanta);
routes = routes.concat(TransformText);

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <header>This is the header</header>
        <RouterProvider router={router} />
        <footer>This is the footer</footer>
    </React.StrictMode>
);
