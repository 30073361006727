import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const TransformText = () => {
    enum TYPES {
        UPPERCASE = "UPPERCASE",
        LOWERCASE = "LOWERCASE",
        CAPITALIZE = "CAPITALIZE",
        DUMBIFY = "DUMBIFY",
    }

    const [output, setOutput] = useState("");
    const [activeType, setActiveType] = useState<TYPES>(TYPES.UPPERCASE);

    const controlFunctions: Record<TYPES, (input: string) => string> = {
        UPPERCASE: (input: string) => input.toUpperCase(),
        LOWERCASE: (input: string) => input.toLowerCase(),
        CAPITALIZE: (input: string) =>
            input.charAt(0).toUpperCase() + input.slice(1).toLowerCase(),
        DUMBIFY: (input: string) => {
            let uppercase = false;
            return input.replace(/\p{L}/gmu, (letter) => {
                uppercase = !uppercase;
                return uppercase ? letter.toUpperCase() : letter.toLowerCase();
            });
        },
    };

    useEffect(() => {
        setOutput((output) => controlFunctions[activeType](output));
    }, [activeType]);

    return (
        <Container>
            <Row>
                <Col>
                    <Form.Group controlId="input">
                        <Form.Label>Texte en entrée</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Entrée"
                            onChange={(e) =>
                                setOutput(
                                    controlFunctions[activeType](e.target.value)
                                )
                            }
                            required
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="output">
                        <Form.Label>Texte en sortie</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Sortie"
                            value={output}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Check
                        label="UPPERCASE"
                        name="type"
                        type="radio"
                        id="type-uppercase"
                        onChange={(e) =>
                            e.target.checked && setActiveType(TYPES.UPPERCASE)
                        }
                        defaultChecked
                    />
                    <Form.Check
                        label="lowercase"
                        name="type"
                        type="radio"
                        id="type-lowercase"
                        onChange={(e) =>
                            e.target.checked && setActiveType(TYPES.LOWERCASE)
                        }
                    />
                    <Form.Check
                        label="Capitalize"
                        name="type"
                        type="radio"
                        id="type-capitalize"
                        onChange={(e) =>
                            e.target.checked && setActiveType(TYPES.CAPITALIZE)
                        }
                    />
                    <Form.Check
                        label="DuMbIfY"
                        name="type"
                        type="radio"
                        id="type-dumbify"
                        onChange={(e) =>
                            e.target.checked && setActiveType(TYPES.DUMBIFY)
                        }
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TransformText;
