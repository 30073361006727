import { RouteObject } from "react-router-dom";
import JoinSecretSanta from "./components/JoinSecretSanta/JoinSecretSanta";
import SecretSanta from "./components/SecretSanta";

const routes: RouteObject[] = [
    {
        path: "/secret-santa",
        element: <SecretSanta />,
    },
    {
        path: "/secret-santa/join/:id",
        element: <JoinSecretSanta />,
    },
];

export default routes;
