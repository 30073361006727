import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import QRCode from "./QRCode/QRCode";
import NameController from "./NameController/NameController";

const SecretSanta: React.FC = () => {
    const [names, setNames] = useState<string[]>([]);
    const [isDisplay, setIsDisplay] = useState(false);
    const [associatedName, setAssociatedName] = useState("");

    const [secretSantaDict, setSecretSantaDict] = useState<
        Record<string, string>
    >({});
    const JSONData = useRef<{ loadFile: boolean }>({
        loadFile: false,
    });

    const submitForm = (e: any) => {
        e.preventDefault();
        addName(e.target.elements.name.value);
        e.target.elements.name.value = "";
    };

    const addName = (name: string) => {
        if (name) setNames((names) => names.concat(name));
    };

    const updateName = (index: number, name: string) => {
        setNames((names) => {
            names[index] = name;
            return [...names];
        });
    };

    const removeName = (index: number) => {
        setNames((names) => {
            const namesCopy = [...names];
            namesCopy.splice(index, 1);
            return namesCopy;
        });
    };

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };

    const generateSecretSanta = (e: any) => {
        e.preventDefault();
        setIsDisplay(true);

        // shuffle namesCopy
        const namesCopy = shuffleArray([...names]);

        // rotate a copy of the array
        const associatedNames = [...namesCopy];
        associatedNames.push(associatedNames.shift());

        setSecretSantaDict(() => {
            const secretSantaDict: Record<string, string> = {};
            // associate key / value
            for (let i = 0; i < namesCopy.length; i++) {
                secretSantaDict[namesCopy[i]] = associatedNames[i];
            }
            return secretSantaDict;
        });
    };

    const cancelGenerateSecretSanta = (e: any) => {
        e.preventDefault();
        setAssociatedName("");
        setIsDisplay(false);
    };

    const showAssociatedName = (e: any) => {
        e.preventDefault();
        const name = secretSantaDict[e.target.elements.selectedName.value];
        setAssociatedName(
            name || "Une erreur est survenue, merci de relancer le tirage"
        );
    };

    const hideAssociatedName = (e: any) => {
        e.preventDefault();
        setAssociatedName("");
    };

    const switchToDisplay = () => {
        if (isDisplay) {
            JSONData.current.loadFile = false;
            setAssociatedName("");
            setIsDisplay(false);
        } else {
            setSecretSantaDict({});
            JSONData.current.loadFile = true;
            setIsDisplay(true);
        }
    };

    const loadFile = (e: any) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.type && !file.type.includes("json")) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            if (reader.result) {
                try {
                    const json = atob((reader.result as string).substring(29));
                    setSecretSantaDict(JSON.parse(json));
                } catch {
                    e.target.value = "";
                }
            }
        });
        reader.readAsDataURL(file);
    };

    return (
        <Container>
            <Row className="justify-content-end">
                <Col xs="auto">
                    <Form>
                        <Form.Group controlId="changeMode">
                            <Form.Check
                                type="switch"
                                label="Mode affichage"
                                checked={isDisplay}
                                onChange={switchToDisplay}
                            />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>

            {isDisplay ? (
                <Row className="justify-content-center mb-5">
                    {JSONData.current.loadFile ? (
                        <>
                            <Col xs={5} className="d-flex align-items-center">
                                <h2>Chargez votre fichier</h2>
                            </Col>
                            <Col xs={3}>
                                <Form.Group controlId="fileInput">
                                    <Form.Label>Fichier</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept="application/json"
                                        onChange={loadFile}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col xs={5} className="d-flex align-items-center">
                                <h2>Secret Santa généré !</h2>
                            </Col>
                            <Col xs={3}>
                                <Row className="mb-2">
                                    <Col>
                                        <Button
                                            onClick={cancelGenerateSecretSanta}
                                            className="w-100 h-100"
                                        >
                                            Annuler le tirage
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="w-100 h-100">
                                            <a
                                                download="secret-santa.json"
                                                href={
                                                    "data:application/json;charset=utf-8, " +
                                                    encodeURIComponent(
                                                        JSON.stringify(
                                                            secretSantaDict
                                                        )
                                                    )
                                                }
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                }}
                                            >
                                                Télécharger le résultat
                                            </a>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            ) : (
                <Row className="justify-content-center mb-5">
                    <Col xs={5}>
                        <Form onSubmit={submitForm}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            Ajouter une personne
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nom"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col
                                    xs={4}
                                    className="d-flex align-items-center"
                                >
                                    <Button variant="primary" type="submit">
                                        Ajouter
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={3} className="d-flex align-items-center">
                        <Button
                            onClick={generateSecretSanta}
                            disabled={names.length < 2}
                            className="w-100"
                        >
                            Tirage aléatoire
                        </Button>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center mb-5">
                <QRCode onAddName={addName} editMode={!isDisplay} />
            </Row>
            <Row className="justify-content-center">
                <Col xs={8}>
                    {isDisplay ? (
                        <>
                            <Row
                                as={Form}
                                onSubmit={showAssociatedName}
                                className="mb-3"
                            >
                                <Col>
                                    <Form.Group controlId="selectedName">
                                        <Form.Label>Choisir un nom</Form.Label>
                                        <Form.Select
                                            onChange={hideAssociatedName}
                                        >
                                            {Object.keys(secretSantaDict)
                                                .sort()
                                                .map((name, index) => (
                                                    <option key={index}>
                                                        {name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={4} className="d-flex align-items-end">
                                    {associatedName ? (
                                        <Button
                                            type="button"
                                            onClick={hideAssociatedName}
                                            className="w-75 h-75"
                                        >
                                            Masquer
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            className="w-75 h-75"
                                        >
                                            Afficher
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row className="ps-5">{associatedName}</Row>
                        </>
                    ) : (
                        names.map((name, index) => (
                            <NameController
                                key={index}
                                name={name}
                                onUpdateName={(name: string) =>
                                    updateName(index, name)
                                }
                                onRemoveName={() => removeName(index)}
                            />
                        ))
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default SecretSanta;
