import { RouteObject } from "react-router-dom";
import ClickWar from "./components/ClickWar";

const routes: RouteObject[] = [
    {
        path: "/click-war",
        element: <ClickWar />,
    },
];

export default routes;
