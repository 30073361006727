import React from "react";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

type WidgetProps = {
    icon?: string;
    title: string;
    link: string;
};

const Widget: React.FC<WidgetProps> = (props) => {
    return (
        <Link
            to={props.link}
            className="d-block"
            style={{ width: 150, height: 150 }}
        >
            <Button className="w-100 h-100">
                {props.icon && <Image src={props.icon} />}
                <p className="mb-0">{props.title}</p>
            </Button>
        </Link>
    );
};

export default Widget;
