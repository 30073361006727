import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

type NameControllerProps = {
    name: string;
    onUpdateName: (name: string) => void;
    onRemoveName: () => void;
};

const NameController: React.FC<NameControllerProps> = (props) => {
    const [edit, setEdit] = useState(false);

    const fieldRef = useRef(null as unknown as HTMLInputElement);

    useEffect(() => {
        fieldRef.current.value = props.name;
    });

    const removeName = (e: any) => {
        e.preventDefault();
        props.onRemoveName();
    };

    const setEditMode = (e: any) => {
        e.preventDefault();
        setEdit(true);
    };

    const cancelUpdate = (e: any) => {
        e.preventDefault();
        fieldRef.current.value = props.name;
        setEdit(false);
    };

    const submitForm = (e: any) => {
        e.preventDefault();
        updateName(fieldRef.current.value);
    };

    const updateName = (name: string) => {
        props.onUpdateName(name);
        setEdit(false);
    };

    return (
        <Row as={Form} className="mb-3" onSubmit={submitForm}>
            <Col>
                <Form.Group controlId={`name-${props.name}`}>
                    <Form.Control ref={fieldRef} disabled={!edit} required />
                </Form.Group>
            </Col>
            <Col>
                {edit ? (
                    <>
                        <Button
                            type="submit"
                            className="me-3"
                            variant="success"
                        >
                            Confirmer
                        </Button>
                        <Button
                            type="button"
                            onClick={cancelUpdate}
                            variant="secondary"
                        >
                            Annuler
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={setEditMode}
                            className="me-3"
                            variant="warning"
                        >
                            Modifier
                        </Button>
                        <Button
                            type="button"
                            onClick={removeName}
                            variant="danger"
                        >
                            Supprimer
                        </Button>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default NameController;
