import { RouteObject } from "react-router-dom";
import TransformText from "./components/TransformText";

const routes: RouteObject[] = [
    {
        path: "/transform-text",
        element: <TransformText />,
    },
];

export default routes;
