import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import io from "socket.io-client";

import { SOCKET_ADRESS } from "../constants";
import Game from "./Game/Game";

const ClickWar = () => {
    const socket = useMemo(() => io(SOCKET_ADRESS), []);

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [isWaiting, setIsWaiting] = useState(false);
    const [gameReady, setGameReady] = useState(false);

    const joinRandom = () => {
        socket.emit("joinRandom");
        setIsWaiting(true);
    };

    const cancelRandom = () => socket.emit("cancelRandom");

    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
        });

        socket.on("cancelRandom", () => setIsWaiting(false));

        socket.on("joinRoom", () => {
            setIsWaiting(false);
            setGameReady(true);
        });

        socket.on("leaveRoom", () => {
            setGameReady(false);
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });

        return () => {
            socket.off("connect");
            socket.off("cancelRandom");
            socket.off("joinRoom");
            socket.off("leaveRoom");
            socket.off("disconnect");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {isConnected ? (
                gameReady ? (
                    <Game socket={socket} />
                ) : isWaiting ? (
                    <>
                        <Row>
                            <Col xs="auto">
                                <Spinner />
                            </Col>
                            <Col xs="auto">
                                <Button onClick={cancelRandom}>Annuler</Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={joinRandom}>
                                    Adversaire aléatoire
                                </Button>
                            </Col>
                        </Row>
                    </>
                )
            ) : (
                <Row>
                    <Col>Impossible de se connecter au serveur</Col>
                </Row>
            )}
        </Container>
    );
};

export default ClickWar;
